import "core-js/modules/es6.regexp.constructor";
import _typeof from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/core-js/modules/es6.promise.js";
import "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/core-js/modules/es6.object.assign.js";
import "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/core-js/modules/es7.promise.finally.js";
Vue.config.devtools = true;
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization

import '@/icons'; // icon
import '@/permission'; // permission control

import BaiduMap from 'vue-baidu-map';

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
import { mockXHR } from "../mock";
/**
 * 目前仅支持MOCK数据
 */
// console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
  //mockXHR()
}
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});
Vue.use(BaiduMap, {
  ak: 'iN7bsekGLrF1apIoi1xzGKypPGToHkNn'
});
Array.prototype.remove = function (elem) {
  var i = this.indexOf(elem);
  if (i >= 0) this.splice(i, 1);
  return this;
};
Array.prototype.insert = function (index, elem) {
  this.splice(index, 0, elem);
  return this;
};
window.isNull = function (value) {
  if (Array.isArray(value)) {
    return value.length == 0;
  }
  return typeof value === 'undefined' || value === null || value === undefined || value === '';
};
Vue.prototype.isNull = window.isNull;
window.trim = function (value) {
  if (isNull(value)) {
    return '';
  }
  return value.replace(/^[ ]+|[ ]+$/img, '');
};
Vue.prototype.trim = window.trim;
window.getImage = function (image) {
  if (!image) {
    return '';
  }
  image = image + "";
  if (image.indexOf('https:') > -1 || image.indexOf('http:') > -1) {
    return image;
  }
  var imageUrl = process.env.VUE_APP_BASE_API + 'image/';
  return imageUrl + image;
};
Vue.prototype.getImage = window.getImage;
window.ab2string = function (arrayBuffer, onSuccess, onFail, thisArg) {
  var reader = new FileReader();
  reader.onload = function (event) {
    if (thisArg) {
      onSuccess.bind(thisArg)(decodeURIComponent(escape(event.target.result)));
    } else {
      onSuccess(decodeURIComponent(escape(event.target.result)));
    }
  };
  reader.onerror = function (event) {
    if (thisArg) {
      onFail.bind(thisArg)(event.target.error);
    } else {
      onFail(event.target.error);
    }
  };
  reader.readAsBinaryString(new Blob([arrayBuffer], {
    type: 'application/octet-stream'
  }));
};
Vue.prototype.ab2string = window.ab2string;
String.prototype.format = String.prototype.format || function () {
  'use strict';

  var str = this.toString();
  if (arguments.length) {
    var t = _typeof(arguments[0]);
    var key;
    var args = 'string' === t || 'number' === t ? Array.prototype.slice.call(arguments) : arguments[0];
    for (key in args) {
      str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
    }
  }
  return str;
};
Vue.prototype.getClientId = function () {
  return this.$store.state.user.clientId;
};
Vue.prototype.getClientId = Vue.prototype.getClientId;
window.isNumber = function (value) {
  return /^[+-]?\d+$/img.test(value);
};
Vue.prototype.isNumber = window.isNumber;
String.prototype.format = function () {
  var args = arguments;
  return this.replace(/\{(\d+)\}/g, function (m, i) {
    return args[i];
  });
};
window.CTP = {
  FLAG_DETAIL_HIDE: 1 //详情页不显示
};
Vue.prototype.CTP = window.CTP;
window.EXCEL_SUFFIX = ".xlsx";
Vue.prototype.EXCEL_SUFFIX = window.EXCEL_SUFFIX;