import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.code to determine if the current user has permission
 * @param routeCodes
 * @param route
 */
function hasPermission(routeCodes, route) {
  if (route.meta && route.meta.code) {
    var result = routeCodes.some(function (code) {
      return route.meta.code === code;
    });
    // console.log(route.meta.code, ' ', result)
    return result;
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param routeCodes
 */
export function filterAsyncRoutes(routes, routeCodes) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(routeCodes, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, routeCodes);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, rolesAndRouteCodes) {
    var commit = _ref.commit;
    var roles = rolesAndRouteCodes.roles,
      routeCodes = rolesAndRouteCodes.routeCodes;
    return new Promise(function (resolve) {
      var accessedRoutes;
      // console.log(routeCodes)
      if (roles.includes('admin') || roles.includes('系统管理员')) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, routeCodes);
      }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};