import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','user']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/password/reset',
  component: function component() {
    return import('@/views/password/reset/index');
  },
  hidden: true
}, {
  path: '/password/forget',
  component: function component() {
    return import('@/views/password/forget/index');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: 'dashboard',
      noCache: true,
      icon: 'dashboard',
      code: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/master',
  component: Layout,
  name: 'index_master',
  redirect: 'noRedirect',
  meta: {
    title: 'master_data',
    icon: 'component',
    code: 'master'
  },
  alwaysShow: true,
  children: [{
    path: 'city',
    redirect: 'noRedirect',
    name: 'city',
    component: function component() {
      return import('@/views/city/index');
    },
    meta: {
      title: 'city',
      code: 'master.city'
    },
    alwaysShow: true,
    children: [{
      path: 'list',
      name: 'city_list',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        title: 'city_list',
        code: 'master.city.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'city_add',
      component: function component() {
        return import('@/views/city/edit/index');
      },
      meta: {
        title: 'city_add',
        noCache: true,
        activeMenu: '/master/city/list',
        breadcrumb: false,
        code: 'master.city.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'city_edit',
      component: function component() {
        return import('@/views/city/edit/index');
      },
      meta: {
        title: 'city_edit',
        noCache: true,
        activeMenu: '/master/city/list',
        breadcrumb: false,
        code: 'master.city.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'city_delete',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        code: 'master.city.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'city_batch',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        code: 'master.city.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'city_download',
      component: function component() {
        return import('@/views/city/list/index');
      },
      meta: {
        code: 'master.city.download'
      },
      hidden: true
    }, {
      path: 'tree_index',
      name: 'city_tree',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        title: 'city_tree',
        activeMenu: '/master/city/tree',
        code: 'master.city.tree'
      },
      hidden: false
    }, {
      path: 'tree_add',
      // name: 'city_add',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        title: 'city_add',
        noCache: true,
        activeMenu: '/master/city/tree',
        breadcrumb: false,
        code: 'master.city.add'
      },
      hidden: true
    }, {
      path: 'tree_edit/:id',
      // name: 'city_edit',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        title: 'city_edit',
        noCache: true,
        activeMenu: '/master/city/tree',
        breadcrumb: false,
        code: 'master.city.edit'
      },
      hidden: true
    }, {
      path: 'tree_delete',
      // name: 'city_delete',
      component: function component() {
        return import('@/views/city/tree/index');
      },
      meta: {
        code: 'master.city.delete'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-city',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'master.city.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'role',
    name: 'role',
    redirect: 'list',
    component: function component() {
      return import('@/views/role/index');
    },
    meta: {
      title: 'role',
      breadcrumb: false,
      code: 'master.role'
    },
    children: [{
      path: 'list',
      name: 'role_list',
      component: function component() {
        return import('@/views/role/list/index');
      },
      meta: {
        title: 'role_list',
        code: 'master.role.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'role_add',
      component: function component() {
        return import('@/views/role/edit/index');
      },
      meta: {
        title: 'role_add',
        noCache: true,
        activeMenu: '/master/role/list',
        breadcrumb: false,
        code: 'master.role.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'role_edit',
      component: function component() {
        return import('@/views/role/edit/index');
      },
      meta: {
        title: 'role_edit',
        noCache: true,
        activeMenu: '/master/role/list',
        breadcrumb: false,
        code: 'master.role.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'role_delete',
      component: function component() {
        return import('@/views/role/list/index');
      },
      meta: {
        code: 'master.role.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'role_batch',
      component: function component() {
        return import('@/views/role/list/index');
      },
      meta: {
        code: 'master.role.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'role_download',
      component: function component() {
        return import('@/views/role/list/index');
      },
      meta: {
        code: 'master.role.download'
      },
      hidden: true
    }]
  }, {
    path: 'position',
    name: 'position',
    redirect: 'index',
    component: function component() {
      return import('@/views/position/index');
    },
    meta: {
      title: 'position',
      breadcrumb: false,
      code: 'master.position'
    },
    children: [{
      path: 'list',
      name: 'position_list',
      component: function component() {
        return import('@/views/position/list/index');
      },
      meta: {
        title: 'position_list',
        code: 'master.position.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'position_add',
      component: function component() {
        return import('@/views/position/edit/index');
      },
      meta: {
        title: 'position_add',
        noCache: true,
        activeMenu: '/master/position/list',
        breadcrumb: false,
        code: 'master.position.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'position_edit',
      component: function component() {
        return import('@/views/position/edit/index');
      },
      meta: {
        title: 'position_edit',
        noCache: true,
        activeMenu: '/master/position/list',
        breadcrumb: false,
        code: 'master.position.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'position_delete',
      component: function component() {
        return import('@/views/position/list/index');
      },
      meta: {
        code: 'master.position.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'position_batch',
      component: function component() {
        return import('@/views/position/list/index');
      },
      meta: {
        code: 'master.position.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'position_download',
      component: function component() {
        return import('@/views/position/list/index');
      },
      meta: {
        code: 'master.position.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-position',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'master.position.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'distributor',
    name: 'distributor',
    redirect: 'index',
    component: function component() {
      return import('@/views/distributor/index');
    },
    meta: {
      title: 'distributor',
      breadcrumb: false,
      code: 'master.distributor'
    },
    children: [{
      path: 'list',
      name: 'distributor_list',
      component: function component() {
        return import('@/views/distributor/list/index');
      },
      meta: {
        title: 'distributor_list',
        code: 'master.distributor.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'distributor_add',
      component: function component() {
        return import('@/views/distributor/edit/index');
      },
      meta: {
        title: 'distributor_add',
        noCache: true,
        activeMenu: '/master/distributor/list',
        breadcrumb: false,
        code: 'master.distributor.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'distributor_edit',
      component: function component() {
        return import('@/views/distributor/edit/index');
      },
      meta: {
        title: 'distributor_edit',
        noCache: true,
        activeMenu: '/master/distributor/list',
        breadcrumb: false,
        code: 'master.distributor.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'distributor_delete',
      component: function component() {
        return import('@/views/distributor/list/index');
      },
      meta: {
        code: 'master.distributor.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'distributor_batch',
      component: function component() {
        return import('@/views/distributor/list/index');
      },
      meta: {
        code: 'master.distributor.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'distributor_download',
      component: function component() {
        return import('@/views/distributor/list/index');
      },
      meta: {
        code: 'master.distributor.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-distributor',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'master.distributor.import_data'
      },
      hidden: true
    }]
    // },
    //   ]
  }, {
    path: 'organization',
    redirect: 'noRedirect',
    name: 'organization',
    component: function component() {
      return import('@/views/organization/index');
    },
    meta: {
      title: 'organization',
      code: 'master.organization'
    },
    alwaysShow: true,
    children: [{
      path: 'list',
      name: 'organization_list',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        title: 'organization_list',
        code: 'master.organization.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'organization_add',
      component: function component() {
        return import('@/views/organization/edit/index');
      },
      meta: {
        title: 'organization_add',
        noCache: true,
        activeMenu: '/master/organization/list',
        breadcrumb: false,
        code: 'master.organization.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'organization_edit',
      component: function component() {
        return import('@/views/organization/edit/index');
      },
      meta: {
        title: 'organization_edit',
        noCache: true,
        activeMenu: '/master/organization/list',
        breadcrumb: false,
        code: 'master.organization.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'organization_delete',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        code: 'master.organization.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'organization_batch',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        code: 'master.organization.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'organization_download',
      component: function component() {
        return import('@/views/organization/list/index');
      },
      meta: {
        code: 'master.organization.download'
      },
      hidden: true
    }, {
      path: 'tree_index',
      name: 'organization_tree',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        title: 'organization_tree',
        activeMenu: '/master/organization/tree',
        code: 'master.organization.tree'
      },
      hidden: false
    }, {
      path: 'tree_add',
      // name: 'organization_add',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        title: 'organization_add',
        noCache: true,
        activeMenu: '/master/organization/tree',
        breadcrumb: false,
        code: 'master.organization.add'
      },
      hidden: true
    }, {
      path: 'tree_edit/:id',
      // name: 'organization_edit',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        title: 'organization_edit',
        noCache: true,
        activeMenu: '/master/organization/tree',
        breadcrumb: false,
        code: 'master.organization.edit'
      },
      hidden: true
    }, {
      path: 'tree_delete',
      // name: 'organization_delete',
      component: function component() {
        return import('@/views/organization/tree/index');
      },
      meta: {
        code: 'master.organization.delete'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-organization',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'master.organization.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'user',
    name: 'user',
    redirect: 'index',
    component: function component() {
      return import('@/views/user/index');
    },
    meta: {
      title: 'user',
      breadcrumb: false,
      code: 'master.user'
    },
    children: [{
      path: 'list',
      name: 'user_list',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        title: 'user_list',
        code: 'master.user.list'
      },
      hidden: false
    }, {
      path: 'add',
      // name: 'user_add',
      component: function component() {
        return import('@/views/user/edit/index');
      },
      meta: {
        title: 'user_add',
        noCache: true,
        activeMenu: '/master/user/list',
        breadcrumb: false,
        code: 'master.user.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      // name: 'user_edit',
      component: function component() {
        return import('@/views/user/edit/index');
      },
      meta: {
        title: 'user_edit',
        noCache: true,
        activeMenu: '/master/user/list',
        breadcrumb: false,
        code: 'master.user.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      // name: 'user_delete',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        code: 'master.user.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      // name: 'user_batch',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        code: 'master.user.batch'
      },
      hidden: true
    }, {
      path: 'download',
      // name: 'user_download',
      component: function component() {
        return import('@/views/user/list/index');
      },
      meta: {
        code: 'master.user.download'
      },
      hidden: true
    }, {
      path: 'import-data',
      name: 'import-data-user',
      component: function component() {
        return import('@/views/import-data/list/index');
      },
      meta: {
        title: 'import_data',
        code: 'master.user.import_data'
      },
      hidden: true
    }]
  }, {
    path: 'shop/authorization/online-list',
    name: 'shop_online_authorization_list',
    component: function component() {
      return import('@/views/shop/authorization/list/index');
    },
    meta: {
      title: 'shop_online_authorization_list',
      code: 'master.shop.authorization.online'
    },
    hidden: false
  }, {
    path: 'shop/authorization/offline-list',
    name: 'shop_offline_authorization_list',
    component: function component() {
      return import('@/views/shop/authorization/list/index');
    },
    meta: {
      title: 'shop_offline_authorization_list',
      code: 'master.shop.authorization.offline'
    },
    hidden: false
  }, {
    path: 'shop/authorization/import-data',
    name: 'import-data-shop-authorization',
    component: function component() {
      return import('@/views/import-data/list/index');
    },
    meta: {
      title: 'import_data',
      code: 'master.shop.authorization.print'
    },
    hidden: true
  }, {
    path: 'shop/audit/view/:id',
    name: 'shop-authorization-audit',
    component: function component() {
      return import('@/views/shop/audit/audit');
    },
    meta: {
      title: 'shop_authorization_audit',
      code: 'master.shop.authorization.audit'
    },
    hidden: true
  }, {
    path: 'shop/audit/list',
    name: 'shop-authorization-audit-list',
    component: function component() {
      return import('@/views/shop/audit/list');
    },
    meta: {
      title: 'shop_authorization_audit',
      code: 'master.shop.authorization.print'
    },
    hidden: true
  }]
}, {
  path: '/sys',
  component: Layout,
  name: 'system_setting',
  redirect: 'noRedirect',
  meta: {
    title: 'system_setting',
    icon: 'setting',
    code: 'system'
  },
  alwaysShow: true,
  children: [{
    path: 'resource',
    name: 'resource',
    redirect: 'list',
    component: function component() {
      return import('@/views/resource/index');
    },
    meta: {
      title: 'resource',
      code: 'system.resource'
    },
    alwaysShow: true,
    children: [{
      path: 'list',
      name: 'resource_list',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        title: 'resource_list',
        code: 'system.resource.list'
      },
      hidden: false
    }, {
      path: 'add',
      component: function component() {
        return import('@/views/resource/edit/index');
      },
      meta: {
        title: 'resource_add',
        noCache: true,
        activeMenu: '/sys/resource/list',
        breadcrumb: false,
        code: 'system.resource.add'
      },
      hidden: true
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/resource/edit/index');
      },
      meta: {
        title: 'resource_edit',
        noCache: true,
        activeMenu: '/sys/resource/list',
        breadcrumb: false,
        code: 'system.resource.edit'
      },
      hidden: true
    }, {
      path: 'delete',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        code: 'system.resource.delete'
      },
      hidden: true
    }, {
      path: 'batch',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        code: 'system.resource.batch'
      },
      hidden: true
    }, {
      path: 'download',
      component: function component() {
        return import('@/views/resource/list/index');
      },
      meta: {
        code: 'system.resource.download'
      },
      hidden: true
    }, {
      path: 'tree_index',
      name: 'resource_tree',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        title: 'resource_tree',
        activeMenu: '/sys/resource/tree',
        code: 'system.resource.tree'
      },
      hidden: false
    }, {
      path: 'tree_add',
      // name: 'resource_add',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        title: 'resource_add',
        noCache: true,
        activeMenu: '/sys/resource/tree',
        breadcrumb: false,
        code: 'system.resource.add'
      },
      hidden: true
    }, {
      path: 'tree_edit/:id',
      // name: 'resource_edit',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        title: 'resource_edit',
        noCache: true,
        activeMenu: '/sys/resource/tree',
        breadcrumb: false,
        code: 'system.resource.edit'
      },
      hidden: true
    }, {
      path: 'tree_delete',
      // name: 'resource_delete',
      component: function component() {
        return import('@/views/resource/tree/index');
      },
      meta: {
        code: 'system.resource.delete'
      },
      hidden: true
    }]
  }, {
    path: 'data-template',
    name: 'data-template',
    redirect: 'list',
    component: function component() {
      return import('@/views/data-template/index');
    },
    meta: {
      title: 'data_template',
      breadcrumb: false,
      code: 'system.datatemplate'
    },
    children: [{
      path: 'list',
      name: 'list',
      component: function component() {
        return import('@/views/data-template/list/index');
      },
      meta: {
        title: 'data_template_list',
        code: 'system.datatemplate'
      },
      hidden: false
    }, {
      path: 'detail',
      name: 'detail',
      component: function component() {
        return import('@/views/data-template/detail/index');
      },
      meta: {
        title: 'data_template_detail',
        code: 'system.datatemplate'
      },
      hidden: true
    }]
  }, {
    path: 'export-file',
    name: 'export-file',
    redirect: 'list',
    component: function component() {
      return import('@/views/export-file/index');
    },
    meta: {
      title: 't_export_file_code',
      breadcrumb: false,
      code: 'system.exportfile'
    },
    children: [{
      path: 'list',
      name: 'export-file-list',
      component: function component() {
        return import('@/views/export-file/list/index');
      },
      meta: {
        title: 't_export_file_code',
        code: 'system.exportfile'
      },
      hidden: false
    }]
  }, {
    path: 'prop-option',
    name: 'prop-option',
    redirect: 'noRedirect',
    alwaysShow: true,
    component: function component() {
      return import('@/views/prop-option/index');
    },
    meta: {
      title: 'data_dictionary_manage',
      breadcrumb: false,
      code: 'system.data_dictionary_manage'
    },
    children: [{
      path: 'data-dictionary',
      name: 'data_dictionary',
      component: function component() {
        return import('@/views/datadictionary/index');
      },
      meta: {
        title: 'data_dictionary_option',
        code: 'system.data_dictionary_option'
      },
      hidden: false
    }, {
      path: 'prop-option-index',
      name: 'prop-option-index',
      component: function component() {
        return import('@/views/prop-option/detail/index');
      },
      meta: {
        title: 'data_dictionary_option',
        code: 'system.data_dictionary_option'
      },
      hidden: true
    }, {
      path: 'client-prop-index',
      name: 'client-prop-index',
      component: function component() {
        return import('@/views/client-prop/detail/index');
      },
      meta: {
        title: 'data_dictionary_prop',
        code: 'system.data_dictionary_prop'
      },
      hidden: false
    }]
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;