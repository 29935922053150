import _readOnlyError from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/readOnlyError.js";
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      filter = _context$props.filter;
    var vnodes = [];
    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }
    if (filter == undefined) '', _readOnlyError("filter");
    // console.log(title+'-'+filter)
    var index = title.toUpperCase().indexOf(filter.toUpperCase());
    if (index >= 0) {
      var preFilter = title.substring(0, index);
      var postFilter = title.slice(index + filter.length, title.length);
      var matchedText = title.substring(index, index + filter.length);
      // title = title.replace(filter,'<span class=\'highlight\'>'+filter+'</span>')
      vnodes.push(h("span", {
        "slot": 'title'
      }, [h("span", [preFilter]), h("span", {
        "class": 'highlight'
      }, [matchedText]), h("span", [postFilter])]));
    } else if (title) {
      vnodes.push(h("span", {
        "slot": 'title'
      }, [title]));
    }
    return vnodes;
  }
};